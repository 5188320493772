import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

const config: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000,
  responseType: 'json',
  headers: { 
		'Access-Control-Allow-Origin': '*',
		'Client': 'Management'
	}
}

const service: AxiosInstance = axios.create(config)

export default service