import { StaffAuthed, StaffEmailPasswordLogin } from "../types/auth"
import { Result } from "../types/common/result"
import service from "./auth-service"

const api = '/staff/auth'

const authAPI = {
  emailPasswordLogin (staffEmailPasswordLogin: StaffEmailPasswordLogin) {
    return service.post<Result<StaffAuthed>>(api + '/login/email-password', staffEmailPasswordLogin)
  } 
}

export default authAPI