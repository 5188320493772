import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { createHead } from '@vueuse/head'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { DateTime } from 'luxon'
import { createApp } from 'vue'
import config from '../package.json'
import App from './App.vue'
import router from './router'
import { key, store } from './store'

DateTime.prototype.toJSON = function () {
  return this.toJSDate().getTime().toString()
}

const app = createApp(App)

const environment = import.meta.env.VITE_ENVIRONMENT as string
if (environment !== 'dev') {
  switch(import.meta.env.VITE_APP_BRAND) {
    case 'CetuSchool': {
      Sentry.init({
        app,
        dsn: 'https://6eccf86c427e4a97825b365a02f13566@o1110879.ingest.sentry.io/6170943',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'web-management@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['management.staging.cetuschool.com', 'management.cetuschool.com', /^\//],
          })
        ]
      })
      break
    }
    case 'DDTalk': {
      Sentry.init({
        app,
        dsn: 'https://bad90bd1335e4c32bc079688cc72b65a@o4504817848549376.ingest.sentry.io/4504829100425216',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'web-management@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['management.staging.ddtalk.me', 'management.ddtalk.me', /^\//],
          })
        ]
      })
      break
    }
  }
}

let icon = ''
let theme = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    icon = '/icon/cetuschool.ico'
    theme = '/css/theme/cetuschool.css'
    break
  }
  case 'DDTalk': {
    icon = '/icon/ddtalk.ico'
    theme = '/css/theme/ddtalk.css'
    break
  }
}
const head = createHead({
  title: import.meta.env.VITE_APP_TITLE,
  link: [
    {
      rel: 'icon',
      href: icon
    },
    {
      rel: 'stylesheet',
      type: 'text/css',
      href: theme
    }
  ]
})

app.component('QuillEditor', QuillEditor)
app.use(head).use(ElementPlus).use(router).use(store, key).mount('#app')
