import { store } from "../../store"

export interface UploadHeaders {
  Client: string
	Authorization: string
}

export class UploadHeadersImpl implements UploadHeaders {
  Client = 'Management'
  Authorization = 'Bearer ' + store.state.accessToken
}