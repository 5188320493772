import { Role } from "./staff"

export class StaffEmailPasswordLogin {
	email?: string
	password?: string
}

export interface AuthStaff {
	id: string
	name: string
	role: Role
	locked: boolean
}

export interface StaffAuthed {
	staff: AuthStaff,
	accessToken: string
}