<template>
  <div class="content">
		<div class="actionbar">
			<div>Teacher List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateTeacherDialog = true">Create Teacher</el-button>

				<el-button text type="primary" @click="showCreateSupportTeacherDialog = true">Create Support Teacher</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.country" placeholder="Country" style="width: 150px;" filterable>
						<el-option v-for="country in countries" :key="country.code" :label="country.name" :value="country.code"></el-option>
					</el-select>
					<el-select v-model="filters.displayCountry" placeholder="Display Country" style="width: 150px; margin-left: 10px;">
						<el-option v-for="option in CountryOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
					<el-select v-model="filters.accent" placeholder="Accent" style="width: 150px; margin-left: 10px;">
						<el-option v-for="option in AccentOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
					<el-select v-model="filters.complete" placeholder="Complete" style="width: 150px; margin-left: 10px;">
						<el-option v-for="option in TeacherCompleteOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
					<el-select v-model="filters.qualified" placeholder="Qualified" style="width: 150px; margin-left: 10px;">
						<el-option v-for="option in TeacherQualifiedOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
					<el-select v-model="filters.locked" placeholder="Status" style="width: 150px; margin-left: 10px;">
						<el-option v-for="option in TeacherLockedOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Name" value="name"></el-option>
								<el-option label="Nickname" value="nickname"></el-option>
								<el-option label="Mobile" value="mobile"></el-option>
								<el-option label="Email" value="email"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="teachers" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Name" prop="fullName" width="200" />
			<el-table-column label="Nickname" prop="nickname" width="100" />    
			<el-table-column label="Identity" prop="vendor" width="90">
				<template #default="scope">{{TeacherFilter.vendor(scope.row.vendor)}}</template>
			</el-table-column>     
			<el-table-column label="Mobile" prop="fullMobile" width="180" />
			<el-table-column label="Email" prop="email" width="300" />
			<el-table-column label="Country" width="150">
				<template #default="scope">
					<div>DC: {{EnumFilter.country(scope.row.displayCountry)}}</div>
					<div>RC: {{TeacherFilter.country(scope.row.country)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Accent" prop="accent" width="90">
				<template #default="scope">
					<div>{{EnumFilter.accent(scope.row.accent)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Rank" prop="rank" width="90">
				<template #default="scope">{{NumberFilter.rank(scope.row.rank)}}</template>
			</el-table-column>
			<el-table-column label="Certificates" prop="certificates" width="110">
				<template #default="scope">
					<div v-for="certificate in scope.row.certificates" :key="certificate.name">{{certificate.name}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Complete" prop="complete" width="100">
				<template #default="scope">
					<div :style="[scope.row.complete? {'color': ''} : {'color': 'red'}]">{{TeacherFilter.complete(scope.row.complete)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Certified" prop="certified" width="100">
				<template #default="scope">
					<div :style="[scope.row.certified? {'color': ''} : {'color': 'red'}]">{{TeacherFilter.certified(scope.row.certified)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Readiness" prop="ready" width="100">
				<template #default="scope">
					<div :style="[scope.row.ready? {'color': ''} : {'color': 'red'}]">{{TeacherFilter.ready(scope.row.ready)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Status" prop="locked" width="90">
				<template #default="scope">
					<div :style="[scope.row.locked? {'color': 'red'} : {'color': ''}]">{{TeacherFilter.locked(scope.row.locked)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="270">
				<template #default="scope">
					<el-button text type="primary" @click="doGetTeacherAuthOtp(scope.row.email)">Get OTP</el-button>
					<el-button text type="primary" @click="doViewTeacher(scope.row.id)">View</el-button>
					<el-popconfirm title="Are you sure to delete the teacher?" @confirm="doDeleteTeacher(scope.row)">
						<template #reference>
							<el-button text type="primary">Delete</el-button>
						</template>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />

		<create-teacher-dialog v-if="showCreateTeacherDialog" v-model:visible="showCreateTeacherDialog" @success="onCreateTeacherSuccess"></create-teacher-dialog>

		<create-support-teacher-dialog v-if="showCreateSupportTeacherDialog" v-model:visible="showCreateSupportTeacherDialog" @success="onCreateSupportTeacherSuccess"></create-support-teacher-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import bypassAPI from '../../api/bypass'
import countryAPI from '../../api/country'
import teacherAPI from '../../api/teacher'
import CreateSupportTeacherDialog from '../../components/teacher/CreateSupportTeacherDialog.vue'
import CreateTeacherDialog from '../../components/teacher/CreateTeacherDialog.vue'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { TeacherFilter } from '../../filters/teacherFilter'
import { AccentOptions, CountryOptions } from '../../types/common/option/enumOptions'
import { TeacherCompleteOptions, TeacherLockedOptions, TeacherQualifiedOptions } from '../../types/common/option/teacherOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { TeacherFilters, TeacherFiltersImpl } from '../../types/common/query/filters/teacherFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { TeacherSearcherImpl } from '../../types/common/query/searcher/teacherSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Country } from '../../types/country'
import { SimpleTeacher, Teacher } from '../../types/teacher'

const loading = ref<boolean>(false)
const router = useRouter()

const teachers = ref<SimpleTeacher[]>()

onMounted(() => {
	doListTeacher()
	doFindAllCountries()
})

// country
const countries = ref<Country[]>()
const doFindAllCountries = function () {
	countryAPI.findAll().then((response: AxiosResponse<Result<Country[]>>) => {
		const result = response.data
		if (result.success) {
			countries.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Query teacher
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<TeacherFilters>(new TeacherFiltersImpl())
const searcher = reactive<Searcher>(new TeacherSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListTeacher = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	teacherAPI.list(query).then((response: AxiosResponse<Result<ListObject<SimpleTeacher>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			teachers.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListTeacher()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListTeacher()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListTeacher()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListTeacher()
}

// View teacher
const doViewTeacher = function(id: string) {
	router.push({name: 'teacher', params: {id: id}})
}

// Delete Enrollment
const doDeleteTeacher = function (teacher: Teacher) {
	loading.value = true
	teacherAPI.deleteById(teacher.id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListTeacher()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Create teacher
const showCreateTeacherDialog = ref<boolean>(false)
const onCreateTeacherSuccess = function () {
	ElMessage.success('Success to create')
	doListTeacher()
}

// Create support teacher
const showCreateSupportTeacherDialog = ref<boolean>(false)
const onCreateSupportTeacherSuccess = function () {
	ElMessage.success('Success to create')
	doListTeacher()
}

// Get teacher auth otp
const doGetTeacherAuthOtp = function (email: string) {
	bypassAPI.getEmailAuthOtp(email).then((response: AxiosResponse<Result<string>>) => {
		const result = response.data
		if (result.success) {
			const otp = result.data
			ElMessage.success(otp)
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
</script>