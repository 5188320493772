<template>
  <div class="content">
		<div class="actionbar">
			<div>Parent List</div>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.type" placeholder="Type">
						<el-option v-for="option in ParentTypeOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.locked" placeholder="Status">
						<el-option v-for="option in ParentLockedOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-if="staff.role !== Role.CS" v-model="filters.courseServiceId" placeholder="Service" filterable>
						<el-option v-for="courseService in courseServices" :key="courseService.id" :label="courseService.name" :value="courseService.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Nickname" value="nickname"></el-option>
								<el-option label="Mobile" value="mobile"></el-option>
								<el-option label="Email" value="email"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="parents" v-loading="loading" border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Type" prop="type" width="100">
				<template #default="scope">{{ParentFilter.type(scope.row.type)}}</template>
			</el-table-column>
			<el-table-column label="Name" width="150">
				<template #default="scope">
					<div>NA: {{scope.row.name}}</div>
					<div>NN: {{scope.row.nickname}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Contact" width="200">
				<template #default="scope">
					<div>M: {{scope.row.fullMobile}}</div>
					<div>E: {{scope.row.email}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Finance" width="150">
				<template #default="scope">
					<div>Balance: {{scope.row.balanceLessons}}</div>
					<div>Freeze: {{scope.row.freezeLessons}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Students" prop="students" width="200">
				<template #default="scope">
					<div v-for="student in scope.row.students" :key="student.id">
						<span>{{student.nickname}}</span>
						<span style="margin-left: 5px;">[{{StudentFilter.status(student.status)}}]</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="Agent" prop="agentName" width="150" />
			<el-table-column label="Service" prop="courseServiceName" width="150" />
			<el-table-column label="Status" width="120">
				<template #default="scope">
					<div>{{ParentFilter.completed(scope.row.complete)}}</div>
					<div>{{ParentFilter.locked(scope.row.locked)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Remark" prop="remark" />
			<el-table-column label="Actions" fixed="right" width="130">
				<template #default="scope">
					<div><el-button text type="primary" :disabled="!scope.row.email" @click="doGetParentEmailAuthOtp(scope.row.email)">Email OTP</el-button></div>
					<div><el-button text type="primary" :disabled="!scope.row.countryCode || !scope.row.mobile" @click="doGetParentMobileAuthOtp(scope.row.countryCode, scope.row.mobile)">Mobile OTP</el-button></div>
					<div><el-button text type="primary" @click="doViewParent(scope.row.id)">View</el-button></div>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import bypassAPI from '../../api/bypass'
import parentAPI from '../../api/parent'
import staffAPI from '../../api/staff'
import { ParentFilter } from '../../filters/parentFilter'
import { StudentFilter } from '../../filters/studentFilter'
import { useStore } from '../../store'
import { ParentLockedOptions, ParentTypeOptions } from '../../types/common/option/parentOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { ParentFilters, ParentFiltersImpl } from '../../types/common/query/filters/parentFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { ParentSearcherImpl } from '../../types/common/query/searcher/parentSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { SimpleParent } from '../../types/parent'
import { NanoStaff, Role } from '../../types/staff'

const loading = ref<boolean>(false)
const router = useRouter()

const parents = ref<SimpleParent[]>()
const store = useStore()
const staff = store.state.staff!

onMounted(() => {
	doFindAllCourseService()
	doListParent()
})

// Find Course Service
const courseServices = ref<NanoStaff[]>()
const doFindAllCourseService = function () {
	loading.value = true
	staffAPI.findByRole(Role.CS).then((response: AxiosResponse<Result<NanoStaff[]>>) => {
		const result = response.data
		if (result.success) {
			courseServices.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Query parent
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ParentFilters>(new ParentFiltersImpl())
const searcher = reactive<Searcher>(new ParentSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListParent = function () {
	if (staff.role === Role.CS) {
		filters.courseServiceId = staff.id
	}
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	parentAPI.list(query).then((response: AxiosResponse<Result<ListObject<SimpleParent>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			parents.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListParent()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListParent()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListParent()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListParent()
}

// Get parent email auth otp
const doGetParentEmailAuthOtp = function (email: string) {
	bypassAPI.getEmailAuthOtp(email).then((response: AxiosResponse<Result<string>>) => {
		const result = response.data
		if (result.success) {
			const otp = result.data
			ElMessage.success(otp)
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Get parent mobile auth otp
const doGetParentMobileAuthOtp = function (countryCode: string, mobile: string) {
	bypassAPI.getMobileAuthOtp(countryCode, mobile).then((response: AxiosResponse<Result<string>>) => {
		const result = response.data
		if (result.success) {
			const otp = result.data
			ElMessage.success(otp)
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// View parent
const doViewParent = function(id: string) {
	router.push({name: 'parent', params: {id: id}})
}
</script>